import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import styles from "./Steps.module.scss";
import 'react-toastify/dist/ReactToastify.css';
import copy from "../../styles/assets/copy-icon.svg";
import checkIcon from "../../styles/assets/check-circle.svg";

export const Steps = () => {
  return (
    <div className={styles.steps} id="howToStart">
      <h4>How to start</h4>
      <h2>Start to Mine with Motherhash in a few steps</h2>
      <div className={styles.steps__container}>
        <div className={styles.step}>
          <span className={styles.number}>1</span>
          <div className={styles.text}>
            <p>Enter link into your ASIC interface</p>
              stratum+tcp://btc.motherhash.io:3333 & <br />
              stratum+tcp://btc.motherhash.io:443
              <br />
              stratum+tcp://btc.motherhash.io:25
              
          </div>
          <button
            onClick={() => {              
              const text="stratum+tcp://btc.motherhash.io:3333 & stratum+tcp://btc.motherhash.io:443 stratum+tcp://btc.motherhash.io:25";
              toast("Link copied", {
                icon: () =>  <img alt="success" src={checkIcon} />
              });
              navigator.clipboard.writeText(text);              
            }}
            className={styles.steps__button}
          >
            Copy link &nbsp;
            <img alt="arrow right" src={copy} />
          </button>
          <ToastContainer
            closeButton={false}
            className={styles.customToast}
            toastClassName={styles.toastWrapper}
            bodyClassName={styles.toastBody}
            position="bottom-center"
            autoClose={4000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <div className={styles.subSteps}>
          <div className={styles.subStep}>
            <span className={styles.number}>2</span>
            <div>Enter a Bitcoin address for your username</div>
          </div>
          <div className={styles.subStep}>
            <span className={styles.number}>3</span>
            <div>Receive bitcoins</div>
          </div>
        </div>
      </div>
    </div>
  );
};
