import React from "react";
import wrap from "../../styles/assets/wrap.png";
import payouts from "../../styles/assets/payouts.png";
import styles from "./Features.module.scss";

export const Features = () => {
  return (
    <div className={styles.features}>
      <div className={styles.features__container}>
        <h1 className={styles.header}>Advantages</h1>
        <div className={styles.features__cards}>
          <div className={styles.features__left}>
            <div className={styles.firstCard}>
              <p>3.7% pps+</p>
              <p>1.7% pplns</p>
            </div>
            <div className={styles.secondCard}>
              <h2>Transparent</h2>
              <span>All data is in your personal account</span>
              <img className={styles.image} src={wrap} alt="wrap" />
            </div>
          </div>
          <div className={styles.features__right}>
            <h2>Daily payouts</h2>
            <span>Enjoy with  zero fees, no delays and secure</span>
            <img alt="payouts" src={payouts} />
          </div>
        </div>
      </div>
    </div>
  );
};
