import fromExponential from 'from-exponential';

export function humanFormat(amount, strictFormat = false) {

  if (!amount && amount !== 0) {
    return '';
  }
  const num = typeof amount === 'string' && !strictFormat ? fromExponential(Number.parseFloat(amount)) : amount;
  const parts = num.toString().split('.');

  return Number(parts[0]).toLocaleString('ru-RU') + (parts[1] ? ('.' + parts[1]) : '');

}
