import React from "react";
import arrowRight from "../../styles/assets/arrow-right.svg";
import { getLkLink } from "../utils/links";
import styles from "./Join.module.scss";

export const Join = () => {
  const link = getLkLink();
  return (
    <div className={styles.join}>
      <div className={styles.join__container}>
        <h2>Join to secure mining with Motherhash</h2>
          <a
            className={styles.join__button}
            href={link}
            rel="noreferrer"
          >
            Start now &nbsp;
            <img alt="arrow right" src={arrowRight} />
          </a>
      </div>
    </div>
  );
};
