import { Footer } from "./shared/Footer";
import { Header } from "./shared/Header";
import { MainContent } from "./shared/MainContent";

function App() {
  return (
    <div className="App" >   
      <Header />  
      <MainContent /> 
      <Footer />
    </div>
  );
}

export default App;
