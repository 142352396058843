import React from "react";
import { HeaderContent  } from "./HeaderContent";
import { HeaderInfo } from "./HeaderInfo";
import { Navbar } from "../Navbar";
import styles from './Header.module.scss';

export const Header = () => {
  return (
    <header className={styles.header}>
      {/* <HeaderInfo /> */}
      <div className={styles.header__main}>
        <Navbar />
        <HeaderContent />
      </div>
    </header>
  );
};
