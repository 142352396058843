import React from "react";
import { Features } from "../Features";
import { Steps } from "../Steps";
import { Join } from "../Join";
import { BitcoinRate } from "../BitcoinRate";
import styles from "./MainContent.module.scss";

export const MainContent = () => {
  return (
    <main className={styles.main}>
      <BitcoinRate />
      <Steps /> 
      <Features />           
      <Join />
    </main>
  );
};
