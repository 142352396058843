import React, { useEffect, useState } from "react";
import { MobileNavbar } from "./MobileNavbar";
import logo from "../../styles/assets/logo.svg";
import mailIcon from "../../styles/assets/mail.svg";
import telegramIcon from "../../styles/assets/telegram.svg";
import { getLkLink } from "../utils/links";
import styles from "./Navbar.module.scss";

export const Navbar = () => {
  const link = getLkLink();
  const [color, setColor] = useState(false);

  //change nav color when scrolling
  const changeColor = () => {
    if(window.scrollY >= 100) {
      setColor(true);
    }
    else {
      setColor(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return () => window.removeEventListener('scroll', changeColor);
  }, [])

  return (
    <div className={`${styles.menuContainer} ${color ? styles.menuContainer__bg: ""}`}>
      <div className={styles.menu}>
        <div className={styles.logo}>
          <a 
            href="/" 
          >
            <img alt="logo" src={logo} />
          </a>
        </div>
        <ul className={styles.menu__list}>
            <li className={styles.point}>
              <a href="#howToStart">How to start</a>
            </li>
            <li className={styles.point}>
              <a href="#dashboard">Dashboard</a>
            </li>
          </ul>
        <div className={styles.menu__container}>
          <ul className={styles.menu__extra}>
            <li className={styles.point}>
              <a href="mailto:pool@motherhash.io" className={styles.icon}>
                <img alt="mail-icon" src={mailIcon} />
              </a>
            </li>
            <li className={styles.point}>
              <a
                rel="noreferrer"
                href="https://t.me/mymotherhash"
                className={styles.icon}
              >
                <img alt="telegram-icon" src={telegramIcon} />
              </a>
            </li>
            <li className={styles.point}>
              <a
                href={link}
                rel="noreferrer"
                className={styles.signinBtn}
              >
                Sign in
              </a>
            </li>
          </ul>
          <MobileNavbar />
        </div>
      </div>
    </div>
  );
};
