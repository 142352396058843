import React, { useState } from "react";
import styles from "./Tooltip.module.scss";

export const Tooltip = ({text, children}) => {

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div 
    className={styles.customTooltip} 
    onMouseEnter={() => setShowTooltip(true)}
    onMouseLeave={() => setShowTooltip(false)}
    >
        {children}
        <div className={`${styles.customTooltip__text} ${showTooltip ? styles.open : ""}`}>
            {text}
        </div>
    </div>
  );
};
