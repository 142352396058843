import React from "react";
import footerLogo from "../../../styles/assets/footer-logo.svg";
import mailIcon from "../../../styles/assets/mail.svg";
import telegramIcon from "../../../styles/assets/telegram.svg";
import styles from "./FooterRules.module.scss";

export const FooterRules = () => {
  return (
    <div className={styles.footer__rules}>
        <div className={styles.logo}>
          <img alt="logo" src={footerLogo} />
        </div>
        <div className={styles.methods}>
          <span>Contact us </span>
          <a href="mailto:pool@motherhash.io" className={styles.icon}>
            <img
              alt="mail icon"
              className="filter-blue"
              src={mailIcon}
            />
          </a>
          <a rel="noreferrer" href="https://t.me/mymotherhash" className={styles.icon}>
            <img
              alt="telegram icon"
              className="filter-blue"
              src={telegramIcon}
            />
          </a>
        </div>
      </div>
  );
};
