import React, { useCallback, useState, useMemo, useRef, useEffect } from "react";
import { formatCurrency } from "../../utils/currencyUtils";
import { getLkLink } from "../../utils/links";
import infoIcon from "../../../styles/assets/info-icon.svg";
import { Tooltip } from "../../Tooltip";
import styles from "./RateCalculation.module.scss";

export const RateCalculation = ({ data }) => {

  const link = getLkLink();

  const [hash, setHash] = useState(1);

  const numberRef = useRef(null);

  const onChangeHandler = useCallback((e) => {        
    setHash(parseFloat(e.target.value));
  }, []);

  const profit = useMemo(() => {
    if (!data || !hash) {
      return 0;
    }
    const result = hash * data?.profitPerTh * data?.priceUsd;
    return formatCurrency(result, "USD", true);
  }, [hash, data]);

  const btcResult = useMemo(() => {
    if (!data || !hash) {
      return 0;
    }

    const result = hash * data?.profitPerTh;
    return formatCurrency(result, "BTC", true);

  }, [hash, data]);

  const maxLengthCheck = ({target}) => {
    if (target.value.length > target.maxLength) {
      target.value = target.value.slice(0, target.maxLength)
    } 
  }

  const handleWheel = (e) => e.preventDefault();

  useEffect(() => {
    let observerRefValue = null;

    if(numberRef.current) {
      observerRefValue = numberRef.current;
    }

    observerRefValue.addEventListener("wheel", handleWheel);
    
    return () => {
      if(observerRefValue) {
        observerRefValue.removeEventListener("wheel", handleWheel);
      }      
    }
  }, []);

  return (
    <div className={styles.calculate}>
      <ul className={styles.list}>
        <li>
          Algorithm:&nbsp;
          <span>SHA256d</span>
        </li>
        <li>
          Block reward BTC:&nbsp;
          <span>3.125 btc</span>
        </li>
        <li>
          Payout threshold:&nbsp;
          <span>0.001 BTC</span>
        </li>
      </ul>
      <div className={styles.profit}>
        <h3>
          Calculate daily profit&nbsp;
          <Tooltip text="The result is the theoretical PPS+ mining yield based on the current difficulty and the average miner fees in the past 7 days. The result is calculated in 0 fee rate. For reference only.">
            <img alt="info" src={infoIcon} />
          </Tooltip>
        </h3>
        <div className={styles.result}>
          <h5 className={styles.label}>Hash power</h5>
          <div className={styles.fields}>
            <label>
              <input
                ref={numberRef}
                onChange={onChangeHandler}
                onInput={maxLengthCheck}
                value={hash}
                maxLength={8}
                min={0}
                type="number"
              />
              <span>TH/s</span>
            </label>
            <div className={styles.total}>
              <span className={styles.total__btc}>≈&nbsp;{btcResult}&nbsp;BTC</span><wbr/>
              <span className={styles.total__dollars}>${profit}</span>
            </div>
          </div>
        </div>
      </div>
      <a
          href={link}
          rel="noreferrer"
          className={styles.calculationButton}
        >
          Next
        </a>
    </div>
  );
};
