export default function debounce(fn, time) {
    let timeout;
    let lastArgs;
  
    return (...args) => {
      lastArgs = args;
  
      if (timeout) {
        clearTimeout(timeout);
      }
  
      timeout = setTimeout(() => fn(...lastArgs), time);
    };
  }