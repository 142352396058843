import React, { useEffect, useState } from "react";
import { RateInfo } from "./RateInfo";
import { RateCalculation } from "./RateCalculation";
import { RateChart } from "./RateChart";
import { getMiningStats } from "../../api";
import styles from "./BitcoinRate.module.scss";

export const BitcoinRate = () => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        getMiningStats().then((response) => {            
            setStats(response?.data || null);
        });
      }, []);

  return (
    <div className={styles.rate__container} id="dashboard">
      <RateInfo info={stats?.currentStats}/>
      <div className={styles.rate__chart}>
        <RateChart data={stats?.historicalStats}/>
        <RateCalculation data={stats?.currentStats} />
      </div>
    </div>
  );
};
