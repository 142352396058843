import { humanFormat } from "./numbersUtils";

const currencies = {
    RUB: { symbol: "₽", name: "Ruble", right: true, fraction: 5 },
    EUR: { symbol: "€", name: "Euro", right: false, fraction: 5 },
    USD: { symbol: "$", name: "Dollar", right: false, fraction: 2 },
    BTC: { symbol: "₿", name: "Bitcoin", right: false, fraction: 8 },
    ETH: { symbol: "⧫", name: "Ethereum", right: false, fraction: 8 },
    ETC: { symbol: "ξ", name: "Ethereum Classic", right: false, fraction: 8 },
    LTC: { symbol: "Ł", name: "Litecoin", right: false, fraction: 8 },
    ZEC: { symbol: "ⓩ", name: "Zcash", right: false, fraction: 8 },
    DASH: { symbol: "Đ", name: "Dash", right: false, fraction: 8 }
  };

export function formatCurrency(amount, currency, hideSymbol = false) {

  const currency1 = currencies[currency] ? currencies[currency] : {
    symbol: "",
    name: `${currency}`,
    right: false,
    fraction: 8
   };

  const curLabel = hideSymbol ? "" : (currency ? (`\u00A0${currency1.symbol}`) : "");
  return humanFormat(amount.toFixed(currency1.fraction)) + curLabel;
}
