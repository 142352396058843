import {useCallback, useLayoutEffect} from "react";

export function ReplainChat({id, children}) {

    const loadChat = useCallback((URL, settings) => {
        window["replainSettings"] = settings;
        const script = document.createElement("script");
        script.async = true;
        script.src = URL;
        const x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(script, x);
    }, [])

    useLayoutEffect(() => {
        if(id) loadChat("https://widget.replain.cc/dist/client.js", { id })
    }, [id]);

    return (
        <>
            {children}
        </>
    )
}
