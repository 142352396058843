import axios from 'axios';

export const baseURL = process.env.REACT_APP_API_URL || '/openapi/v1';

export const getMiningStats = async () => {
  try {
    const response = await axios.get(`${baseURL}/miningstats`);

    const data = response.data;

    return data;
  } catch (errors) {
    console.error(errors);
  }
};
