import { humanFormat } from './numbersUtils';

const KILO = Math.pow(10, 3);
const MEGA = Math.pow(10, 6);
const GIGA = Math.pow(10, 9);
const TERA = Math.pow(10, 12);
const PETA = Math.pow(10, 15);
const EXA = Math.pow(10, 18);

export function prepareValue(number, degree, strictFormat = false) {
  const formatValue = number ? (number / degree) : 0;
  return strictFormat && number > 0 ? formatValue.toPrecision(3) : String(Math.round(formatValue));
}

export function formatHashrateToKilo(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, KILO, strictFormat), strictFormat) + (!hideLabel ? ' Khs' : '');
}

export function formatHashrateToMega(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, MEGA, strictFormat), strictFormat) + (!hideLabel ? ' Mhs' : '');
}

export function formatHashrateToPeta(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, PETA, strictFormat), strictFormat) + (!hideLabel ? ' Phs' : '');
}

export function formatHashrateToExa(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, EXA, strictFormat), strictFormat) + (!hideLabel ? ' Ehs' : '');
}

export function formatHashrateToGiga(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, GIGA, strictFormat), strictFormat) + (!hideLabel ? ' Ghs' : '');
}

export function formatHashrateToTera(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, TERA, strictFormat), strictFormat) + (!hideLabel ? ' Ths' : '');
}

export function formatMegaHashrateToGiga(mega, hideLabel = false, strictFormat = false) {
  return humanFormat(prepareValue(mega, 1000, strictFormat), strictFormat) + (!hideLabel ? ' Ghs' : '');
}

export function formatHashrateToHuman(mega, hideLabel = false, strictFormat = false) {
  const countOfDigits = String(mega).length;
  const newDivided = Math.pow(10, countOfDigits - 1);

  if (newDivided >= EXA) {
    return formatHashrateToExa(mega, hideLabel, strictFormat);
  }
  if (newDivided >= PETA) {
    return formatHashrateToPeta(mega, hideLabel, strictFormat);
  }
  if (newDivided >= TERA) {
    return formatHashrateToTera(mega, hideLabel, strictFormat);
  }
  if (newDivided >= GIGA) {
    return formatHashrateToGiga(mega, hideLabel, strictFormat);
  }
  if (newDivided >= MEGA) {
    return formatHashrateToMega(mega, hideLabel, strictFormat);
  }
  if (newDivided >= KILO) {
    return formatHashrateToKilo(mega, hideLabel, strictFormat);
  }
  return humanFormat(Math.round(mega || 0)) + (!hideLabel ? ' Hs' : '');
}
