import React, { useMemo } from "react";
import BtcIcon from "../../../styles/assets/btc-icon.svg";
import { formatHashrateToHuman } from "../../utils/hashrateUtils";
import { formatCurrency } from "../../utils/currencyUtils";
import styles from "./RateInfo.module.scss";

export const RateInfo = (props) => {
  const { info } = props;

  const dailyProfit = useMemo(() => {
    if (!info) {
      return 0;
    }
    const result = info?.profitPerTh * info?.priceUsd;
    return formatCurrency(result || 0, "USD", true);
  }, [info]);

  return (
    <div className={styles.rate__info}>
      <div className={styles.logo}>
        <img alt="btc icon" src={BtcIcon} />
        &nbsp;BTC
      </div>
      <div className={styles.currencies}>
        <ul className={styles.list}>
          <li>
            Daily profit:
            <span>${dailyProfit}/Т</span>
          </li>
          <li>
            BTC Price:
            <span>{formatCurrency(info?.priceUsd || 0, "USD", true)} USD</span>
          </li>
          <li>
            Pool Hashrate:
            <span>
              {formatHashrateToHuman(info?.poolHashrate, false, false)}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
