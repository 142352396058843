import React from "react";
import { FooterRules } from "./FooterRules";
import { FooterContacts } from "./FooterContacts";
import styles from "./Footer.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <FooterRules />
        <FooterContacts />
      </div>
    </footer>
  );
};
