import React from "react";
import mailIcon from "../../../styles/assets/mail-icon.svg";
import telegramIcon from "../../../styles/assets/telegram-icon.svg";
import styles from "./MobileNavbar.module.scss";

export const MobileNavbar = () => {
  return (
    <div className={styles.hamburgerMenu}>
      <input id={styles.menu__toggle} type="checkbox" />
      <label className={styles.menu__btn} htmlFor={styles.menu__toggle}>
        <span></span>
      </label>
      <ul className={styles.menu__box}>
        <li className={styles.point}>
          <a href="#howToStart" className={styles.menu__item}>
            How to start
          </a>
        </li>
        <li className={styles.point}>
          <a href="#dashboard" className={styles.menu__item}>Dashboard</a>
        </li>
        <li className={styles.point}>
          <a href="mailto:pool@motherhash.io" className={styles.spacing}>
            <img alt="mail-icon" src={mailIcon} />
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/mymotherhash">
            <img alt="telegram-icon" src={telegramIcon} />
          </a>
        </li>
      </ul>
    </div>
  );
};
