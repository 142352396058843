import React from "react";
import { getLkLink } from "../../utils/links";
import styles from "./HeaderContent.module.scss";

export const HeaderContent = () => {
  const link = getLkLink();

  return (
    <div className={styles.headerContent}>
      <h2 className={styles.header}>
        Pool <span className={styles.motherhash}>Motherhash</span> provides clients
        with secure and comfortable BTC mining
      </h2>
      <h4 className={styles.text}>
        We offer PPS+PPLNS model with free daily payouts and detalised features for asics management
      </h4>
      <div className={styles.button__container}>
        <a
          href={link}
          rel="noreferrer"
          className={styles.button__signIn}
        >
          Sign in
        </a>
      </div>
    </div>
  );
};
