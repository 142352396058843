import React from "react";
import {
  CartesianGrid,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatHashrateToHuman } from "../../utils/hashrateUtils";
import { formatCurrency } from "../../utils/currencyUtils";
import styles from "./RateChart.module.scss";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export const RateChart = (props) => {
  const { data } = props;

  const { width } = useWindowDimensions();  

  const MetricTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x="60" y="-12" dy="16" fontSize="12" textAnchor="end" fill="#757575">
          {formatHashrateToHuman(payload.value, false, true)}
        </text>
      </g>
    );
  };

  const DateTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x="0" y="0" dy="16" fontSize="12" textAnchor="middle" fill="#757575">
          {payload.value}
        </text>
      </g>
    );
  };

  const CurrencyTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x="-30" y="-15" dy="20" fontSize="12" textAnchor="middle" fill="#757575">
          {formatCurrency(payload.value, "USD", false)}
        </text>
      </g>
    );
  };

  function CustomTooltip({ payload, active }) {
    if (active && payload?.length) {
      const [item] = payload;
      const data = Object.entries(item.payload);
      return (
        <div className={styles.tooltip}>
          {data.map(([key, value], i) => {
            return (
              <div key={i}>
                {key === "date" && (
                  <p>
                    <b>{value}</b>
                  </p>
                )}
                {key === "priceUsd" && (
                  <p>
                    <b>BTC Price:</b>&nbsp;{formatCurrency(value, "USD", false)}
                  </p>
                )}
                {key === "poolHashrate" && (
                  <p>
                    <b>Pool Hashrate:</b>&nbsp;
                    {formatHashrateToHuman(value, false, false)}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      );
    }
    return <></>;
  }

  return (
    <div className={styles.chart}>
      <h3>30-Day Hashrate Chart</h3> 
      <div className={styles.mobileContainer}>     
        <div className={styles.mobileFixedWidth}>      
          <ResponsiveContainer width="100%" height={275}>
            <LineChart
              width={616}
              height={300}
              data={data || []}
              margin={{
                top: 5,
                right: 30,
                left: width <= 600 ? 4 : 20,
                bottom: 5,
              }}
            >
              <XAxis tick={DateTick} dataKey="date" tickLine={false} axisLine={false}/>   
              <YAxis 
                axisLine={false}
                tickLine={false}
                tick={MetricTick} 
                yAxisId="right" 
                orientation="right"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={CurrencyTick}       
                yAxisId="left" 
                orientation="left"  
              />         
              <Tooltip content={CustomTooltip} />
              <Legend
                iconType="circle"
                iconSize={16}
                wrapperStyle={{
                  fontSize: "16px",
                  fontWeight: 600,
                  paddingTop: "20px",
                }}
              />
              <Line
                strokeWidth={4}
                isAnimationActive={false}
                dot={false}
                type="monotone"
                dataKey="poolHashrate"
                stroke="#D6D6D6"
                name="Pool Hashrate"
                yAxisId="right"
              />
              <Line
                strokeWidth={4}
                isAnimationActive={false}
                dot={false}
                yAxisId="left"
                type="monotone"
                dataKey="priceUsd"
                stroke="#F7CA04"
                name="BTC Price"
              />
              <CartesianGrid horizontal={true} vertical={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>  
      </div>
    </div>
  );
};
