import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.scss';
import {ReplainChat} from "./shared/ReplainChat";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ReplainChat id={process.env.REACT_APP_REPLAIN_CHAT_ID} >
          <App />
      </ReplainChat>
  </React.StrictMode>
);
