import React from "react";
import privacyPolicy from "../../../docs/Privacy Policy.docx";
import termsOfUse from "../../../docs/TERMS OF USE.docx";
import styles from "./FooterContracts.module.scss";

export const FooterContacts = () => {
  return (
    <div className={styles.footer__contacts}>
        <div className={styles.copyright}>© {new Date().getFullYear()} Motherhash. All rights reserved</div> 
        <div className={styles.policy}>          
          <a href={privacyPolicy}>Privacy Policy</a>
          <a href={termsOfUse}>Terms of use</a>
        </div>
      </div>
  );
};
